import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', name: 'home', component: () => import('@/views/Home.vue') },
        { path: '/cv', name: 'cv', component: () => import('@/views/Cv.vue') },
        { path: '/portfolio', name: 'portfolio', component: () => import('@/views/Portfolio.vue') },
        { path: '/contact', name: 'contact', component: () => import('@/views/Contact.vue') },
        { path: '/:catchAll(.*)', name: '404', component: () => import('@/views/404.vue') }
    ]
})

let current
let loadingAssets

router.beforeEach((to, from, next) => {
    if (current !== to.href && !loadingAssets) {
        store.commit('startLoading')
        current = to.href
        loadingAssets = true
    }
    next()
})

router.afterEach(to => {
    if (loadingAssets) {
        store.commit('finishLoading')
        loadingAssets = false
    }

    document.title = `${to.name} · Eli van der Does`
})

export default router
