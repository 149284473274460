import store from '@/store'
import NProgress from 'nprogress'

NProgress.configure({ showSpinner: false, minimum: 0.2, trickleSpeed: 100 })

store.watch(state => state.loading, (processesLeft, oldValue) => {
    if (processesLeft === 0) return NProgress.done()
    if (oldValue === 0) return NProgress.start()
    if (oldValue > processesLeft) NProgress.inc()
})
