import axios from 'axios'
import store from '@/store'

// https://medium.com/@LoCascioNick/create-a-global-loading-progress-indicator-using-vuex-axios-and-nprogress-20451b33145a
const http = axios.create()

http.interceptors.request.use(config => {
    store.commit('startLoading')
    return config
}, error => {
    store.commit('finishLoading')
    return Promise.reject(error)
})

http.interceptors.response.use(response => {
    store.commit('finishLoading')
    return response
}, error => {
    store.commit('finishLoading')
    return Promise.reject(error)
})

export default http
