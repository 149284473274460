import { createStore } from 'vuex'

export default createStore({
    state() {
        return {
            loading: 0
        }
    },
    mutations: {
        startLoading (state) {
            state.loading++
        },
        finishLoading (state) {
            state.loading--
        }
    },
    getters: {
        loading (state) {
            return state.loading
        }
    }
})
