<template>
    <div class="wood z-50 overflow-hidden bg-[#4e342e]">
        <div class="p-6 flex justify-center">
            <img src="~@/media/eli-van-der-does.png" alt class="select-none h-20">
        </div>
        <div class="right-2 top-2 absolute flex items-center bg-[#0a0a0a]/20 rounded-lg pr-5 pl-2 select-none" v-if="weather && weather.temperature">
            <img class="h-20" :src="`https://cdn.buienradar.nl/resources/images/icons/weather/116x116/${weather.icon}.png`" alt>
            <div class="text-right">
                <div>
                    <span class="text-3xl">{{ weather.temperature.toFixed(1) }}</span>
                    <span class="text-xl">&deg;C</span>
                </div>
                <span class="text-lg leading-3">Den Haag</span>
            </div>
        </div>
        <div class="p-2 bg-[#0a0a0a]/75 text-xl">
            <ul class="flex justify-center flex-row gap-5">
                <li v-for="link in navigation">
                    <SlidingUrl :to="link.to" class="font-bold">{{ link.name }}</SlidingUrl>
                </li>
            </ul>
        </div>
    </div>
    <div class="px-10 py-7 max-w-4xl mx-auto">
        <router-view />
    </div>
    <div class="fixed left-5 bottom-5 text-xl rotate-[270deg] -translate-x-1/2 -translate-y-24 select-none">
        <span>&copy; {{ currentYear }} elivanderdoes.nl</span>
    </div>
</template>

<script>
import SlidingUrl from '@/components/SlidingUrl.vue'
import axios from 'axios'

export default {
    name: 'App',
    components: {
        SlidingUrl
    },
    data() {
        return {
            weather: null,
            currentYear: new Date().getFullYear(),
            navigation: [
                { name: 'home', to: '/' },
                { name: 'cv', to: '/cv' },
                { name: 'portfolio', to: '/portfolio' },
                { name: 'contact', to: '/contact' }
            ]
        }
    },
    methods: {
        async updateWeather() {
            axios.get('https://observations.buienradar.nl/1.0/actual/weatherstation/6215').then(response => {
                this.weather = {
                    temperature: response.data.temperature,
                    icon: response.data.iconcode
                }
            })
        }
    },
    async created() {
        await this.updateWeather()
        setInterval(await this.updateWeather, 60000)
    },
    mounted() {
        const background = require(`@/media/wood/${Math.floor(Math.random() * 27)}-Low.jpg`)
        document.getElementsByClassName('wood')[0].style.background = `url('${background}') fixed top center`
    }
}
</script>
