<template>
    <span>
        <a v-if="to.startsWith('https://') || external" class="underline-effect" :href="to" :target="blank? '_blank' : ''">
            <slot/>
        </a>
        <router-link v-else class="underline-effect" :to="to">
            <slot/>
        </router-link>
    </span>
</template>

<script>
export default {
    name: 'InlineUrl',
    props: {
        to: String,
        blank: Boolean,
        external: Boolean
    }
}
</script>

<style scoped>
.underline-effect::before {
    content: '';
    position: absolute;
    height: .15em;
    width: 0;
    left: 0;
    bottom: -.1em;
    background: #d9a922;
    border-radius: 1em;
    transition: width .2s ease;
}

.underline-effect:hover:before {
    width: 100%;
}

.underline-effect {
    position: relative;
}
</style>
